import LittleShop from "../components/web/LittleShop";
import NavBar from "../components/web/NavBar";

const HomePage = () => {
  return (
    <div className="home">
      <div className="homeContainer">
          <NavBar />
          <LittleShop />
          <div style={{padding:"40px", textAlign:"right", fontSize:"12px"}}>V2.0.3</div>
      </div>
    </div>
  );
};

export default HomePage;
